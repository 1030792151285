export const checkAuth=(authCode)=>{
    let authList=JSON.parse(localStorage.getItem('authList')) || [] //所有权限列表
    let hasAuth=authList.some(item=>item.authCode==authCode)
    return hasAuth // true | false
}
let projectList={   //项目列表权限
    //项目列表
        'jbxx':"基本信息",
        'ssjh':"实施计划",
        'wlqd':"物料清单",
        'sgrz':"施工日志",
        'cylb':"成员列表",
        'xmspb':"项目审批表",
        'xmfj':"项目附件",
        'xj':"巡检",
        'sgftj':'施工方提交',
        'tsjy':'投诉建议',
        'cwxx':"财务信息"
}
export const checkProjectAuth=()=>{ // 判断是否勾选了项目列表模块其中之一
    let hasAuth=false
    let authCodeList=Object.keys(projectList)
    authCodeList.forEach(item=>{
        if(checkAuth(item)){
            hasAuth=true
        }
    })
    return hasAuth
}
export const getAuthCodeByName=(name)=>{
    let authCode=''
  for(let i in projectList){
    if(projectList[i]==name){
        authCode=i
    }
  }
  return authCode
}

/**
 * 公司管理模块
 */
let companyList={  //公司管理模块权限
    rygl:"人员管理",
    gwgl:"岗位管理",
    dgxx:"对公信息",
    gzgl:"公章管理",
    clgl:"车辆管理"
  }
  export const getCompanyAuthCodeByName=(name)=>{
    let authCode=''
    for(let i in companyList){
      if(companyList[i]==name){
          authCode=i
      }
    }
    return authCode
  }
  export const checkCompanyAuth=()=>{ // 判断是否勾选了公司管理模块其中之一
    let hasAuth=false
    let authCodeList=Object.keys(companyList)
    authCodeList.forEach(item=>{
      if(checkAuth(item)){
        hasAuth=true
      }
    })
    return hasAuth
  }
  // 公司管理----->人员管理权限模块
  let companyMemberList={
    'gsgl-rygl-xz':"新增",
    'gsgl-rygl-bj':"编辑",
    'gsgl-rygl-sc':"删除",
    'gsgl-rygl-jb':'解绑',
    'gsgl-rygl-bd':'绑定',
  }
  export const getCompanyMemberAuthCodeByName=(name)=>{
    let authCode=''
    for(let i in companyMemberList){
        if(companyMemberList[i]==name){
            authCode=i
        }
    }
    return authCode
  }
