<template>
    <div>
        <el-dialog title="添加" :visible.sync="dialogVisible" width="50%">
            <el-row>
                <el-form label-width="300px">
                    <el-col span="21">
                        <el-form-item>
                            <el-input type="text" v-model="queryInfo.keyword" placeholder="请输入筛选条件">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col span="3" style="display: flex; justify-content: end;">
                        <el-button type="primary" @click="getTableData()">搜索</el-button>
                    </el-col>
                </el-form>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-table :data="tableData" border fit style="width: 100%" @row-click="handleRowClick"
                        :row-key="(row) => { row.id }" height="530px" ref="multipleTable">
                        <!-- <el-table-column type="selection" width="55" align="center">
                    </el-table-column> -->

                        <el-table-column type="index" label="序号" align="center"></el-table-column>
                        <el-table-column min-width="140px" label="电站名称" align="center"
                            prop="powerStationName"></el-table-column>
                    </el-table>
                </el-col>
                <el-col :span="12" class="selectedUl pr10 bbox">
                    <div style="padding: 10px;">
                        <p class="wp100 bbox flex justify-between textblue">
                            <span>已选择</span>
                            <!-- <span class="pointer" @click="emptyAuthority"><i class="el-icon-delete"></i> 清空</span> -->
                        </p>
                        <div class="selectedUl-box" style="height: 500px; overflow: scroll;">
                            <ul>
                                <li class="clearfix" v-for="(item, i) in multipleSelection"
                                    :key="item.powerStationCode">
                                    <span class="rightOrderBox1">{{
                                        item.powerStationName
                                        }}</span>
                                    <i class="el-icon-close floatRight iconOrganization iconblue fw700 f15"
                                        @click="delAuthority(i)"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row type="flex" justify="center" style="margin-top: 10px">
                <el-button type="primary" @click="toAdd">添加</el-button>
                <el-button @click="dialogVisible = false">取消</el-button>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            queryInfo: {},
            multipleSelection: [],
            row: {}
        };
    },
    methods: {
        init(row) {
            this.dialogVisible = true
            this.row = row
            this.togetSelect()
            this.queryInfo = {}
        },
        // 初始化时调用
        async togetSelect() {
            await this.getTableData()
            await this.queryStationList()
        },
        // 鼠标单击事件
        handleRowClick(val) {
            let index = this.multipleSelection.findIndex(item => { return item.powerStationCode == val.powerStationCode })
            if (index > -1) {
                this.multipleSelection.splice(index, 1)
            } else {
                this.multipleSelection.push(val)
            }
        },
        // 删除选中的数据
        delAuthority(index) {
            this.multipleSelection.splice(index, 1)
        },
        async getTableData() {
            let data = {
                userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
                ...this.queryInfo
            }
            let res = await this.$http.post("powerStation/queryList", data);
            if (res.data.code == 200) {
                this.tableData = res.data.data
            }
        },
        async toAdd() {
            let data = {
                userCode: this.row.userCode,
                powerStationCodeList: this.multipleSelection.map((item) => item.powerStationCode)
            }
            let res = await this.$http.post("powerStationUser/addUserStation", data);
            if (res.data.code == 200) {
                this.$emit('distributionAdd')
                this.dialogVisible = false
            }
        },
        async queryStationList() {
            let data = {
                userCode: this.row.userCode,
            }
            let res = await this.$http.post("powerStationUser/queryStationList", data);
            if (res.data.code == 200) {
                this.multipleSelection = []
                let echoList = res.data.data
                //保证有值再进行比对，避免报错
                if (echoList && this.tableData.length != 0) {
                    //遍历table表格数据与要回显的数组比对，匹配成功的设置为选中状态
                    this.tableData.forEach((row) => {
                        echoList.forEach((item) => {
                            if (row.powerStationCode == item.powerStationCode) {
                                this.multipleSelection.push(row)
                                //判断唯一标识，若是id一样，则设置该行选中状态为true
                                // this.multipleSelection.push(row)
                            }
                        })
                    })
                }
            }
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    }
};
</script>
<style></style>