<template>
  <div>
    <el-dialog title="添加" :visible.sync="dialogVisible" width="50%">
      <el-form label-width="300px">
        <el-row>
          <el-col span="21">
            <el-form-item>
              <el-input
                type="text"
                v-model="queryInfo.keyword"
                placeholder="请输入筛选条件"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col span="3" style="display: flex; justify-content: end;">
            <el-button type="primary" @click="search()">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col span="12">
          <el-table
            :data="tableData"
            border
            fit
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick"
            :row-class-name="tableRowClassName"
            height="530px"
            ref="multipleTable"
            :row-key="
              row => {
                row.id;
              }
            "
          >
            <!-- <el-table-column type="selection" width="55" align="center">
                            </el-table-column> -->
            <el-table-column
              type="index"
              label="序号"
              align="center"
            ></el-table-column>
            <el-table-column
              min-width="140px"
              label="电站名称"
              align="center"
              prop="powerStationName"
            >
              <template slot-scope="scope">
                <span :style="scope.row.flag ? 'color: #409EFF' : ''">{{
                  scope.row.powerStationName
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12" class="selectedUl pr10 bbox">
          <div style="padding: 10px;">
            <p class="wp100 bbox flex justify-between textblue">
              <span>已选择</span>
              <!-- <span class="pointer" @click="emptyAuthority"><i class="el-icon-delete"></i> 清空</span> -->
            </p>
            <div
              class="selectedUl-box"
              style="height: 500px; overflow: scroll;"
            >
              <ul>
                <li
                  class="clearfix"
                  v-for="(item, i) in multipleSelection"
                  :key="item.powerStationCode"
                >
                  <span class="rightOrderBox1">{{
                    item.powerStationName
                  }}</span>
                  <i
                    class="el-icon-close floatRight iconOrganization iconblue fw700 f15"
                    @click="delAuthority(i)"
                  ></i>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" style="margin-top: 10px">
        <el-button type="primary" @click="toAdd">添加</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      multipleSelection: [],
      currentRowTree: {},
      echoList: [{ id: 1 }],
      queryInfo: {}
    };
  },
  methods: {
    init(currentRowTree, echoList) {
      this.dialogVisible = true;
      this.queryInfo = {};
      this.currentRowTree = currentRowTree;
      this.togetSelect();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //获取单击事件的所有索引
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 鼠标单击事件
    handleRowClick(val) {
      let index = this.multipleSelection.findIndex(item => {
        return item.powerStationCode == val.powerStationCode;
      });
      if (index > -1) {
        this.multipleSelection.splice(index, 1);
        this.tableData[val.index].flag = false;
      } else {
        this.multipleSelection.push(val);
        this.tableData[val.index].flag = true;
      }
    },
    // 删除选中的数据
    delAuthority(index) {
      this.multipleSelection.splice(index, 1);
    },
    // 初始化时调用
    async togetSelect() {
      await this.getTableData(0);
      await this.getQueryList();
    },
    // 获取原来列表
    async getQueryList() {
      this.multipleSelection = [];
      let data = {};
      data.companyCode = this.currentRowTree.companyCode;
      const res = await this.$http.post("companyPowerStation/queryList", data);
      if (res.data.code == 200) {
        this.echoList = res.data.data;
        //保证有值再进行比对，避免报错
        if (this.echoList && this.tableData.length != 0) {
          //遍历table表格数据与要回显的数组比对，匹配成功的设置为选中状态
          this.tableData.forEach(row => {
            this.echoList.forEach(item => {
              if (row.powerStationCode == item.powerStationCode) {
                //判断唯一标识，若是id一样，则设置该行选中状态为true
                this.multipleSelection.push(row);
                row.flag = true;
                console.log(row, "rowwwwwwwwwwwwwwwwwwwww");
                // this.$refs.multipleTable.toggleRowSelection(row, true)
              } else {
                // row.flag = false;
              }
            });
          });
          console.log(this.tableData, "this.tableData");
        }
      }
    },
    // 获取当前列表
    async getTableData(index = 1) {
      let data = { ...this.queryInfo };
      const res = await this.$http.post("powerStation/queryList", data);
      if (res.data.code == 200) {
        this.tableData = res.data.data;
        if (index == 1) {
          this.tableData.map(item => {
            let index = this.multipleSelection.findIndex(i => {
              return i.powerStationCode == item.powerStationCode;
            });
            if (index !== -1) {
              item.flag = true;
            } else {
            }
          });
        }
      }
    },
    async search() {
      await this.getTableData();
      //   await this.normalSearch();
    },
    async normalSearch() {
      this.tableData.map(item => {
        let index = this.multipleSelection.findIndex(i => {
          return i.powerStationCode == item.powerStationCode;
        });
        if (index !== -1) {
          item.flag = true;
        } else {
        }
      });
    },
    // 添加
    async toAdd() {
      const data = {};
      data.companyCode = this.currentRowTree.companyCode;
      data.companyName = this.currentRowTree.companyName;
      data.userName = JSON.parse(localStorage.getItem("userInfo")).name;
      data.powerStationList = this.multipleSelection;
      const res = await this.$http.post(
        "companyPowerStation/addCompanyStation",
        data
      );
      if (res.data.code == 200) {
        this.$emit("emitAddPower");
        this.dialogVisible = false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.rightOrderBox1 {
  width: 90%;
}
</style>
