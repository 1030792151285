
export const       editorOption={
    placeholder:'暂无描述',
modules:{
toolbar:[
['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
['blockquote', 'code-block'],     //引用，代码块
[{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
[{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
[{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
[{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
[{ 'direction': 'rtl' }],             // 文本方向
[{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
[{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
[{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
[{ 'font': [] }],     //字体
[{ 'align': [] }],    //对齐方式
['clean'],    //清除字体样式
// ['image','video']    //上传图片、上传视频
]
}}
 //0合同章，1发票章，2法人章，3公章，4财务章
export const officialsealTypeOptions=[
    {value:0,label:'合同章'},
    {value:1,label:'发票章'},
    {value:2,label:'法人章'},
    {value:3,label:'公章'},
    {value:4,label:'财务章'},
]
export const rulesAccountForm={

}
export const custodianProps={
    expandTrigger:'hover',
    emitPath:false,
    label:"label",
    children:'childrens'
}
export const operatorProps={
    expandTrigger:'hover',
    emitPath:false,
    label:"label",
    children:'childrens',
    multiple:true
}
export const handlercustodianOptions=(arr)=> {
    let list=arr.map(item=>{
        let data={label:item.type==0?item.deptName:item.userName,value:item.type==0?item.deptCode:item.userCode,type:item.type,disabled:(item.type==0 &&  (item.childrens.length==0 || item.childrens==null)) ? true:false,leaf: item.type==0? true:false}
        if(item.childrens.length>0){
          data.childrens=handlercustodianOptions(item.childrens)  //递归查找
        }
         return data
      })
      return list
}
export const tabList=[
    {
      label:"人员管理",
      activeIndex:1,
      currentRowType:1,
    },
  //  {
  //     label:"部门职责",
  //    activeIndex:2,
  //    currentRowType:1,
  //   },
    {
      label:"电站管理",
      activeIndex:3,
       currentRowType:0,
    },
    // {
    //   label:"对公信息",
    //   activeIndex:5,
    //    currentRowType:0,
    // },
    // {
    //   label:"公章管理",
    //  activeIndex:4,
    //   currentRowType:0,
    //  },
    //  {
    //   label:"车辆管理",
    //   activeIndex:6,
    //   currentRowType:0,
    //  },
  ]


